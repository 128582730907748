<template>
  <GameInterface />
  <TurnCounter />
</template>

<script>
import GameInterface from './components/game-interface.vue'

export default {
  name: 'App',
  components: {
    GameInterface
  }
}
</script>

<style>
#app {
  font-family: Menlo, Terminal, Courier;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin-top: 20px; */
}
body {
  background: #000000;
}
html, body { height: 100%; width: 100%; }
body { margin: 0px; padding: 0px }

.user-msg:last-child {
    list-style: "--> ";
}
label {
    font-weight: 900;
    font-style: italic;
	display: none;
}
</style>
