
<template>
  <div class="game-interface">
    <div>
      <div class="health" style="display: inline; padding-right: 20px;" >Player &#10084;: {{userHealth}}</div>
      <div v-if="monsterToFight === true" class="monster" style="display: inline; padding-right: 20px;" >Monster &#10084;: {{monsterHealth}}</div>
      <div class="killcount" style="display: inline; padding-right: 20px;" >Kill Count: {{killCount}}</div>
      <div class="weapon" style="display: inline; padding-right: 20px;" >Weapon: {{currentWeapon}}</div>
	</div>
    <div class="game-outcome">{{outcome}}</div>
  </div>
  <div class="playground-container">
    <ol>
		<template v-for="(item, index) in journey">
		<li v-if="item.msgType == 'user'" :key="'story-ol-div-'+index" :id="'story-ol-li-'+index"
		v-html="item.msg"
		>
		</li>
		<div v-else :key="'story-ol-div-'+index" :id="'story-ol-div-'+index"
		v-html="item.msg"
		>
		</div>
		</template>
    </ol>
  </div>
  <div class="weapon-listing-container" v-if="showWeaponsOnGround === true">
	<p style="color:#fff; font-style: italic; font-weight: bolder; margin-top: 0px;">Weapon List:</p>
    <ol>
		<li v-for="(item, index) in weaponsOnGround" :key="'weapon-listing-li-'+index" :id="'story-ol-li-'+index"
		>
		{{item.weapon}}
		</li>
    </ol>
  </div>
</template>

<script>
//import TurnCounter from './turn-counter.vue'
//import PlayGround from './play-ground.vue'
import monstersData from '../../etc/json/enemies-data.json'
import weapons from '../../library/weapons.json'
import taunts from '../../library/taunts.json'
import waits from '../../library/waits.json'
import ouchies from '../../library/ouchies.json'
import monsterTypes from '../../library/monster-type.json'

export default {
  components: {
    //TurnCounter,
	//PlayGround,
  },
  name: 'GameInterface',
  data() {
    return {
      turnCounter: 0,
      monsterStepCounter: 0,
      strikeCounter: 0,
      turnCounterId: 'turn-counter',
      action: '',
      outcome: '',	
      monsters: monstersData,
      monsterTypes: monsterTypes,
      userHealth: 100,
      monsterHealth: 0,
      mStrengthBooster: 1,
      cmi: 0,
      attackCount: 0,
      mAttackCount: 0,
      killCount: 0,
      currentWeapon: 'Cricket Bat',
      choices : [
      {'action': 'Attack','choice': 'A'},
      {'action': 'Parry','choice': 'F'},
      {'action': 'Pickup','choice': 'P'},
      {'action': 'Drop','choice': 'D'},
      {'action': 'Taunt','choice': 'T'},
      {'action': 'Wait','choice': 'W'},
      ],
      weapons: weapons,
      weaponsOnGround : [],
      currentUserChoices : [],
      userCurrentAction : '',
      userLog : [],
      weaponOnGround : '',
      weapontoPick: false,
      journey: [],
      userTurn: false,
      typeCounter: 0,
      userOptions: '',
      ouchies: ouchies,
      taunts: taunts,
      waits: waits,
      currentMonster: {},
      monsterToFight: false,
      monsterVisible: false,
      showWeaponsOnGround: false,
      validPlayingOptions : ['A', 'P', 'D', 'T', 'F', 'W'],
      battleHistory: {"beforeBattle":[],"inBattle":[],"allActions":[],"cheatcodeTracker": {"Death":[],"Critcal":[],"Major":[],"Minor":[]}},
    }
  },
  async mounted() {
	let currentUsername = await this.generateUsername()
	console.log("Username :"+ currentUsername)
	this.monsterHealth = this.monsters[this.cmi].strength
	this.currentMonster = this.monsters[this.cmi]
	var that = this
	this.addItemTo("<span style='color: white;'>### Day 1 Post Apocalypse ###</span><BR><!--Kids in bed, house boarded up, a card game and the last of dinner still on the table.<BR>-->As twilight creeps in noises begin to start outside, groans, drums and distant screams.<BR>You kiss your wife goodbye, maybe for the last time, and head out the front door.<BR>Before you is a narrow archway, small in a giant wall that has been your family's saving grace.<BR>As you approach the entry, you can "+this.currentMonster.walk[0]+"<BR>---<BR><span style='color: white;'>You choose to:</span>",null,'general')
	this.getUserOptions()
	
	document.addEventListener("keydown", async (event) => {
		if(that.userTurn) {
			
			let keyPressed = event.key.toUpperCase()
			let validPlayingOptions = this.validPlayingOptions
			if(this.showWeaponsOnGround) {
				validPlayingOptions = await this.getPickupOptions()
				if( !isNaN(parseInt(keyPressed)) ){
					keyPressed = parseInt(keyPressed)
				}
				if( validPlayingOptions.includes(keyPressed) ) {
					that.userTurn = false
					let currentChoice = that.getChoiceObject(keyPressed)
					let damageResponse = await that.response(currentChoice[0].weapon, this.currentMonster, keyPressed)
					if(damageResponse) {
						that.userTurn = true
						this.addItemTo("<label style='font-weight: bold; font-style: italic'>Pickup:</label> "+this.weapons[currentChoice[0].weapon]['action']['pickup'],this.journey.length - 1)
						this.userTurn = false
						this.getUserOptions()
						//await that.addItemTo(this.userOptions+".")
						that.handleChoice({'action': 'Pickup','choice': 'P'})
						this.userTurn = true
					}
				} else {
					that.userTurn = false
					that.getUserOptions()
					await that.addItemTo("Choose a valid option from weapon list.",that.journey.length - 1)
					that.userTurn = true
				}
				return
			} else {
				if( validPlayingOptions.includes(keyPressed) ) {
					that.userTurn = false
					let currentChoice = that.getChoiceObject(keyPressed)
					let validOption = await this.validateUserChoices(currentChoice[0].action)
					if(validOption === true) {
						this.userCurrentAction = currentChoice[0].action
						this.battleHistory.allActions.push(this.userCurrentAction)
						let cheatcodes = this.currentMonster?.cheatcode ? this.currentMonster?.cheatcode : false
						let cheatcodeCheckResponse = []
						if( cheatcodes ) {
							cheatcodeCheckResponse = await this.checkCheatcodes(cheatcodes)
						}
						//await that.monsterAttack(this.userCurrentAction, cheatcodeCheckResponse)
						let damageResponse = await that.response(this.userCurrentAction, this.currentMonster,cheatcodeCheckResponse)
						this.playSound()
						if(damageResponse === true) {
							
							let optionKey = that.getOptionKey(keyPressed)
							if(keyPressed == "P") {
								//that.userTurn = false
								console.log('Ground'+that.weaponsOnGround)
								console.log("SHOW PICKUP OPTIONS")
							} else if(that.choices[optionKey]) {
								that.userTurn = true
								that.handleChoice(that.choices[optionKey],false,cheatcodeCheckResponse)
							}	
						}
						await that.monsterAttack(this.userCurrentAction, cheatcodeCheckResponse)
						return
					} else {
						await that.addItemTo("Choose a valid option, "+that.userOptions+".",that.journey.length - 1)						
						that.userTurn = true
						return
					}
								
				} else {
					that.userTurn = false
					that.getUserOptions()
					await that.addItemTo("Choose a valid option, "+that.userOptions+".",that.journey.length - 1)
					that.userTurn = true
				}
			}
			
			
		}
	})
  },
  methods: {
    incrementTurnCounter() {
      this.turnCounter += 1;
    },
    async handleChoice(choice, skipTurnCounter=false, cheatcodes=[]) {
      if(choice) {
        this.currentUserChoices.push(choice.choice)
        this.action = choice.action
        if( this.monsterToFight ) {
			let monsterReaction = await this.monsterReactions(this.currentMonster, this.action)
			if(monsterReaction.reaction == "Attack" || monsterReaction.reaction == "Parry" ) {
				let damagePointByMonster = await this.damage(monsterReaction,this.currentMonster['weapon'],'human')
				// console.log('Monster Damage: '+damagePointByMonster)

				if( this.monsterHealth > 0 && this.monsterToFight === true ) {
                  if(this.userHealth - damagePointByMonster < 1) {
					this.userHealth = 0
                  } else {
					this.userHealth = this.userHealth - damagePointByMonster
                  }
				}	
			}	
		}
              
        if( this.action == "Attack" || this.action == "Parry"  ) {
          let damagePointByUser = await this.damage(this.action,this.currentWeapon,this.currentMonster.type,cheatcodes)
          this.attackCount++
          this.monsterHealth = this.monsterHealth - damagePointByUser
        }
		
		if(skipTurnCounter === false)	{
          this.incrementTurnCounter()  
        }
		
		}
    },
    async damage(action, weapon, monster, cheatcodes=[]) {
      return new Promise((resolve) => {
        let actions = action
        // console.log('------ // '+monster+' DAMAGE // ------')
        // SET MODIFIERS
        let critical = 4
        let major = 2.5 
        let minor = 1.5
        let damage = this.weapons[weapon]['damage']
		let cheatcodesDamage = 1
		if(cheatcodes.length) {
			//cheatcodes[0].toLowerCase() == 'death'
			if( cheatcodes[0].toLowerCase() == 'critical' ) {
				cheatcodesDamage = critical
			} else if( cheatcodes[0].toLowerCase() == 'major' ) {
				cheatcodesDamage = major
			} else if( cheatcodes[0].toLowerCase() == 'minor' ) {
				cheatcodesDamage = minor
			}
		}
        // console.log('Base Damage: '+damage)

        // CHECK MATERIAL WEAKNESS
        let material = this.weapons[weapon]['material']
        // console.log('Weapon Material: '+material)
        if (material == this.monsterTypes[monster]['weakness']['material']['critical']){
          damage = Math.floor(damage * critical)
        } else if (material == this.monsterTypes[monster]['weakness']['material']['major']) {
          damage = Math.floor(damage * major)
        } else if (material == this.monsterTypes[monster]['weakness']['material']['minor']) {
          damage = Math.floor(damage * minor)
        }
        // CHECK WEAPON WEAKNESS
        let type = this.weapons[weapon]['type']
        // console.log('Weapon Type: '+type)
        if (type == this.monsterTypes[monster]['weakness']['weapon']['critical']){
          damage = Math.floor(damage * critical)
        } else if (type == this.monsterTypes[monster]['weakness']['weapon']['major']) {
          damage = Math.floor(damage * major)
        } else if (type == this.monsterTypes[monster]['weakness']['weapon']['minor']) {
          damage = Math.floor(damage * minor)
        }
        // console.log('Damage Action: '+actions)
        if (actions == "Parry") {
          let parry = this.weapons[weapon]['parry']
          damage = Math.floor(damage * parry)
        }
		
		damage = Math.floor(damage * cheatcodesDamage)
        resolve(damage)
        // console.log("Final Damage: "+damage)
      })
    },
    async response(userAction, monsterInfo,cheatcodes=[]) {
    // Need to get the current weapon or spell sheet
    // Get the current enemy Material and Weapon weakness + resistances and if they;re healed by anything.
    // Add a modifier - Critical x4, Major x2.5, Minor X 1.5, and divide for resistance.
    // Make this robust for both Monster Damage to User and from.
	console.log('------ // RESPONSE // ------')
	console.log('User Action: '+ userAction)
    // console.log(monsterInfo)
		return new Promise((resolve) => {
			(async () => {
				if(this.showWeaponsOnGround) {
					await this.manageWeaponOnGround("Pickup",userAction)
					// need to check any scenario in which we need to perform ant other action
					return resolve(true)
				} else {
					// DROP
					if(userAction == "Drop") {
						await this.handleWeapontoPick(userAction)
						if( this.monsterToFight === true ) {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Drop:</label> <span class='drop'>"+this.weapons[this.weaponOnGround]['action']['drop']+"</span>")
						} else {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Drop:</label> <span class='drop'>"+this.weapons[this.weaponOnGround]['action']['drop']+"</span>",this.journey.length - 1)	
						}
					// PICK UP
					} else if(userAction == "Pickup") {
						//this.addItemTo("<label style='font-weight: bold; font-style: italic'>Pickup:</label> "+this.weapons[this.weaponOnGround]['action'][userAction],this.journey.length - 1)
						this.showWeaponsOnGround = true
						this.userTurn = false
						if( this.monsterToFight === true ) {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Pickup:</label> Pick the weapon :",this.journey.length - 1)
						} else {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Pickup:</label> Pick the weapon :",this.journey.length - 1)
						}
						await this.handleWeapontoPick(userAction)
					// ATTACK
					} else if(userAction == "Attack") {
						let dmg = await this.damage(userAction,this.currentWeapon,this.currentMonster.type,cheatcodes)
						let weaponSound = await this.weaponAction(this.currentWeapon,"sound")
						let weaponAttack = await this.weaponAction(this.currentWeapon,"attack")
						await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Attack:</label> <span class='weapon-sound'>"+ weaponSound +"</span> You "+ weaponAttack +" "+this.currentMonster.name+" for <span class='user-hit-points'>"+ dmg +" points.</span>",this.journey.length - 1)
					// TAUNT
					} else if(userAction == "Taunt") {
						if( this.monsterToFight === true ) {	
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Taunt:</label> <span class='taunt' style='text-transform: uppercase; color: #ffcc33;'>"+this.taunt()+"</span>",this.journey.length - 1)	
						} else {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Taunt:</label> <span class='taunt' style='text-transform: uppercase; color: #ffcc33;'>"+this.taunt()+"</span>",this.journey.length - 1)	
						}
					// PARRY
					} else if(userAction == "Parry") {
						let dmg = await this.damage(userAction,this.currentWeapon,this.currentMonster.type,cheatcodes)
						let weaponParry = await this.weaponAction(this.currentWeapon,"parry")
						await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Parry:</label> You "+weaponParry+" "+this.currentMonster.name+" for <span class='user-hit-points'>"+ dmg +" </span> points.",this.journey.length - 1)
					// WAIT
					} else if(userAction == "Wait") {
						if( this.monsterToFight === true ) {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Wait:</label> <span class='wait'>"+this.wait()+"</span>",this.journey.length - 1)	
						} else {
							await this.addItemTo("<label style='font-weight: bold; font-style: italic'>Wait:</label> <span class='wait'>"+this.	wait()+"</span>",this.journey.length - 1)
						}
					} else {
						return resolve(false)
					}
					return resolve(true)
				}
			})(resolve)
		})
    },
	handleWeapontoPick(userAction){
	return new Promise((resolve) => {
		
		if(userAction == "Pickup") {
			this.weapontoPick = false
			//this.manageWeaponOnGround(userAction,this.weaponOnGround)
			//this.currentWeapon = this.weaponOnGround
			//this.weaponOnGround = ''
			resolve('resolve')
		} else if(userAction == "Drop") {
			this.weapontoPick = true
			this.manageWeaponOnGround(userAction,this.currentWeapon)
			this.weaponOnGround = this.currentWeapon
			this.currentWeapon = ''
			resolve('resolve')
		}
		resolve('resolve')
	})
	},
	manageWeaponOnGround(action, weapon) {
		return new Promise((resolve) => {
			let weaponIndex = this.weaponsOnGround.findIndex(x => x.weapon == weapon);
			var that = this
			if(action == "Pickup") {
				if (weaponIndex > -1) {
					this.weaponsOnGround.splice(weaponIndex, 1) //need to call function to manage the new optiona and after selecting the weapon remow it from list
					if(this.currentWeapon) {
						let newIndex = this.weaponsOnGround.length + 1
						this.weaponsOnGround.push({'choice':newIndex,'weapon':this.currentWeapon})
					}
					this.weaponsOnGround.forEach(function(item, index) {
						that.weaponsOnGround[index].choice = index + 1
					})
					this.currentWeapon = weapon
					this.showWeaponsOnGround = false
				}
			} else {
				if (weaponIndex == -1) {
					if(weapon) {
						let newIndex = this.weaponsOnGround.length + 1
						this.weaponsOnGround.push({'choice':newIndex,'weapon':weapon})
						this.weaponsOnGround.forEach(function(item, index) {
							that.weaponsOnGround[index].choice = index + 1
						})
					}
				}
			}
			resolve('resolve')
		})
	},
	delayTyping() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve('resolved')
			}, 50)
		})
	},
	getPickupOptions() {
		return new Promise(resolve => {
			let returnChoice = []
			this.weaponsOnGround.map((item) => {
				returnChoice.push(item.choice)
			} )
			resolve(returnChoice)
		})
	},
	getChoiceObject(choice) {
		if(this.showWeaponsOnGround) {
			let returnChoice = this.weaponsOnGround.filter((item) => {
				return item.choice == choice
			})
			return returnChoice
		} else {
			let returnChoice = this.choices.filter((item) => {
				return item.choice == choice
			})
			return returnChoice	
		}
		
	},
	getUserOptions() {
		this.userOptions = ''
		var that = this
		that.choices.forEach(function(item) {
			if(item.action == "Pickup") {
				if(that.weaponsOnGround.length > 0) {
					that.userOptions+= ' '+ item.choice +'. '+ item.action+','
				}
			}
			if(item.action == "Drop") {
				if(that.currentWeapon) {
					that.userOptions+= ' '+ item.choice +'. '+ item.action+','
				}
			}
			if( ( item.action == "Attack" || item.action == "Parry" ) && that.monsterToFight === true) {
				if(that.currentWeapon) {
					that.userOptions+= ' '+ item.choice +'. '+ item.action+','
				}
			}
			if(item.action == "Taunt" && that.monsterVisible === true ) {
				that.userOptions+= ' '+ item.choice +'. '+ item.action+','
			}
			if(item.action == "Wait") {
				that.userOptions+= ' '+ item.choice +'. '+ item.action+','
			}		
		})
		that.userOptions = that.userOptions.slice(0, -1)
	},
	getOptionKey( option ) {
		let indexToReturn = false
		if(option) {
			this.choices.forEach(function(item, index) {
				if(item.choice == option) {
					indexToReturn =  index
				}
			})
		}
		return indexToReturn
	},
	taunt() {
		// Get an taunt at random
		const randomIndex = Math.floor(Math.random() * this.taunts.length)
		const randomTaunt = this.taunts[randomIndex]
		return randomTaunt
	},
	wait() {
		// Get an taunt at random
		const randomIndex = Math.floor(Math.random() * this.waits.length)
		const randomWait = this.waits[randomIndex]
		return randomWait
	},
	ouch() {
		// Get an ouchie at random
		const randomIndex = Math.floor(Math.random() * 10)
		const randomWord = this.ouchies[randomIndex]
		return randomWord
	},

  //retort(this.currentMonster,this.monsterStepCounter,this.userAction)
	async retort(monster,battleHistory,action) {
		return new Promise((resolve) => {
			(async () => {
				let counter = await this.getActionCountInBattle(battleHistory, action)
				if(counter > 0) {
					counter = counter - 1
				}
				let retort = ''
				if (action == "Taunt") {
					retort = monster["taunt"][counter]
				} else if (action == "Wait") {
					retort = monster["wait"][counter]
				} else if (action == "Drop" || action == "Pickup") {
					retort = monster["pickup"][counter]
				}
				if (retort) {
					return resolve(retort)
				} else {
					return  resolve("nonn!!")
				}
			})(resolve)
		})
	},
  weaponAction(weapon,action) {
    return new Promise((resolve) => {
      let soundInfo = this.weapons[weapon]["action"][action]
      let soundLength = soundInfo.length
      let isArray = Array.isArray(soundInfo)
      if( soundLength > 0 && isArray) {
        let randomIndex = Math.floor(Math.random() * soundLength)
        let randomWord = soundInfo[randomIndex]
        resolve(randomWord)
      } else {
        let single = soundInfo
        resolve(single)
      }
    })
  },
	async addItemTo(textToAdded,index=null,messageType='user') {
		return new Promise((resolve) => {
			(async () => {
				this.userTurn = false
				if(index) {
					this.journey[index] = {'msg':textToAdded,'msgType':messageType}
				} else {
					this.journey.push({'msg':textToAdded,'msgType':messageType})
				}
					
				await this.$nextTick()
				let inb = this.journey.length - 1
				if(index) {
					inb = index
				}
				let liId = "story-ol-li-"+inb
				if(messageType == "user") {
					document.getElementById(liId).classList.add("user-msg")
				} else if(messageType == "monster") {
					liId = "story-ol-div-"+inb
					document.getElementById(liId).classList.add("monster-msg")
				} else if(messageType == "death") {
					liId = "story-ol-div-"+inb
					document.getElementById(liId).classList.add("death-msg")
				} else if(messageType == "general") {
					liId = "story-ol-div-"+inb
					document.getElementById(liId).classList.add("general-msg")
				} else {
					liId = "story-ol-div-"+inb
					document.getElementById(liId).classList.add("info-msg")
				}
				
				this.typeCounter = 0
				
				if(inb == 0) {
					await this.addItemTo(this.userOptions)
					this.userTurn = true
				}
				this.userTurn = true
				resolve('resolve')
			})(resolve)
		})
	},
	async monsterAttack(currentChoice=null, cheatcodeCheckResponse=[]) {
		return new Promise((resolve) => {
			(async () => {
			if( this.monsterHealth > 0 && this.monsterToFight === true ) {
				let monsterReaction = await this.monsterReactions(this.currentMonster, currentChoice)
				
				if(cheatcodeCheckResponse.includes("Death")) {
					let reactionMsg = await this.monsterReactionsMsg(this.currentMonster, monsterReaction.reaction)
					this.monsterHealth = 0
					let addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg
					await this.addItemTo(addItemMsg,this.journey.length - 1,'monster')
					return resolve('resolve')
				}
				let dmg = await this.damage(currentChoice,this.currentMonster['weapon'],'human', cheatcodeCheckResponse)
				let weaponSound = await this.weaponAction(this.currentMonster['weapon'],"sound")
				let weaponAttack = await this.weaponAction(this.currentMonster['weapon'],"attack")
				let addItemMsg = ""
				if( monsterReaction.reaction == "Attack" || monsterReaction.reaction == "Parry") {
					addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+ weaponSound +" "+ this.currentMonster.name +" "+ weaponAttack + "s "+ this.currentMonster.pronoun +" <span class='weapon' style='text-transform: lowercase'>"+ this.currentMonster.weapon +"</span> into you for <span class='monster-hit-points'>"+ dmg  + "</span> points. <span class='ouchy'>"+this.ouch()+"</span>"
				} else {
					let reactionMsg = await this.monsterReactionsMsg(this.currentMonster, monsterReaction.reaction)
					if( monsterReaction.reaction == "Wait" ) {
						addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg// this.currentMonster[monsterReaction.reaction]
					} else if( monsterReaction.reaction == "Drop" ) {
						addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg	
					} else if( monsterReaction.reaction == "Pickup" ) {
						addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg
					} else if( monsterReaction.reaction == "Taunt" ) {
						addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg
					} else if( monsterReaction.reaction == "End" ) {
						this.monsterHealth = 0
						addItemMsg = "<label style='font-weight: bold; font-style: italic'>"+ monsterReaction.reaction +":</label> "+" "+reactionMsg
					}
						
					// wait : Wait, drop : Drop, pickup : Pickup, taunt : Taunt
				}
				await this.addItemTo(addItemMsg,this.journey.length - 1,'monster')
				await this.addItemTo(this.userOptions)
			}
			return resolve('resolve')
			})(resolve)
		})
	},
	validateUserChoices(currentChoice){
		return new Promise((resolve) => {
			if(currentChoice == "Drop") {
				if(!this.currentWeapon) {
					resolve(false)
				} else {
					resolve(true)
				}
			} else if(currentChoice == "Pickup") {
				if(this.weaponsOnGround.length < 1) {
					resolve(false)
				} else {
					resolve(true)
				}
			} else if(currentChoice == "Attack" || currentChoice == "Parry") {
				if(!this.currentWeapon || this.monsterToFight === false) {
					resolve(false)
				} else {
					resolve(true)
				}
			} else if(currentChoice == "Taunt") {
				if(this.monsterVisible === false) {
					resolve(false)
				} else {
					resolve(true)
				}
			} else if(currentChoice == "Wait") {
				resolve(true)
			} else {
				resolve(false)
			}
		})
	},
	getActionCountInBattle(battle,action){
		return new Promise((resolve) => {
			let actionCount = 0
			if(battle.length > 0 && action)
			battle.forEach(function (item) {
				if (item == action) {
					actionCount++
				}
			})
			resolve(actionCount)
		})
	},
	async monsterWalkActions(monsterRetort){
		return new Promise((resolve) => {
			(async () => {
			if (this.monsterStepCounter == 1) {
				this.monsterVisible = true
				await this.addItemTo("<span class='retort retort-1'>"+ monsterRetort +"</span> Its " + this.currentMonster.name + ", "+this.currentMonster.how +" "+this.currentMonster.type+ " " +this.currentMonster.past,null,'general')
			} else if (this.monsterStepCounter == 2) {
				await this.addItemTo("<span class='retort retort-2'>"+ monsterRetort +"</span> "+ this.currentMonster.name +" "+ this.currentMonster.walk[1] + ", only steps away.",null,'general')
			} else if (this.monsterStepCounter == 3) {
				await this.addItemTo("<span class='retort retort-3'>"+ monsterRetort +"</span> "+ this.currentMonster.name +" "+ this.currentMonster.walk[2] + ", close.",null,'general')
			} else {
				await this.addItemTo("<span class='retort retort-4'>"+ monsterRetort +"</span> "+ this.currentMonster.name +" "+ this.currentMonster.walk[2] + ", close.",null,'general')
			}
			return resolve("resolve")
			})(resolve)
		})
	},
	async monsterReactions(currentMonster,userAction){
		return new Promise((resolve) => {
			(async () => {
				let result = {"reaction":"Attack"}
				if( currentMonster && userAction ){
					result = currentMonster['reactions'][userAction]	
				}
				return resolve(result)
			})(resolve)
		})
	},
	async monsterReactionsMsg(currentMonster,reaction){
		return new Promise((resolve) => {
			(async () => {
				let indexVal = 'wait'
				if( reaction == "Wait" ) {
					indexVal = 'wait'
				} else if( reaction == "Drop" ) {
					indexVal = 'drop'
				} else if( reaction == "Pickup" ) {
					indexVal = 'pickup'
				} else if( reaction == "Taunt" ) {
					indexVal = 'taunt'
				} else if( reaction == "End" ) {
					indexVal = 'end'
				}
				if(Array.isArray(currentMonster[indexVal])) {
					const randomIndex = Math.floor(Math.random() * currentMonster[indexVal].length)
					const randomMsg = this.taunts[randomIndex]
					return resolve(randomMsg)
				} else {
					const randomMsg = currentMonster[indexVal]
					return resolve(randomMsg)
				}
				
				
			})(resolve)
		})
	},
	checkCheatcodes(cheatcodes){
		return new Promise((resolve) => {
			let keysCheatcode = Object.keys(cheatcodes)
			let returnResponse = []
			var that = this
			keysCheatcode.forEach(function(item) {
				let compareWith = that.battleHistory.allActions
				if(that.battleHistory.cheatcodeTracker[item].length > 0) {
					let currentIndex = that.battleHistory.cheatcodeTracker[item].length - 1
					let sliceIndex = that.battleHistory.cheatcodeTracker[item][currentIndex]
					compareWith = compareWith.slice(sliceIndex)
				}
				
				if(compareWith.length >= cheatcodes[item].length ){
					if( that.battleHistory.allActions.toString().includes(cheatcodes[item].toString()) ){
						that.battleHistory.cheatcodeTracker[item].push(that.battleHistory.allActions.length)
						returnResponse.push(item)
					}
				}
			})
			resolve(returnResponse)
		})
	},
	generateUsername() {
		return new Promise((resolve) => {
		let result = ''
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		const charactersLength = characters.length
		let counter = 0
		while (counter < 10) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
			counter += 1
		}
		resolve(result)
		})
	},
	playSound (sound = "./assets/audio/sample-audio.mp3") {
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
  },
  watch: {
	async turnCounter() {
		this.monsterStepCounter++
		if( this.monsterHealth < 1 && this.monsterToFight === true ) {
			this.monsterToFight = false
			this.weapontoPick = true
			this.cmi++
			this.killCount++
			this.currentMonster = this.monsters[this.cmi]
			this.getUserOptions()
			if(this.monsters[this.cmi-1].weapon){
				await this.addItemTo(this.monsters[this.cmi-1].name +this.monsters[this.cmi-1].death + this.monsters[this.cmi-1].pronoun+" "+ this.monsters[this.cmi-1].weapon +" is on the ground.",null,'death')
				this.weaponOnGround = this.monsters[this.cmi-1].weapon
				await this.manageWeaponOnGround('monster-die', this.weaponOnGround)
			} else {
				await this.addItemTo(this.monsters[this.cmi-1].name +this.monsters[this.cmi-1].death,null,'death')
			}
			this.monsterVisible = false
			//await this.addItemTo("we've loaded a monster in the header, but he should be too far for the user to know about",null,'general')
			// await this.addItemTo(this.monsters[this.cmi].name +" "+this.monsters[this.cmi].walk[0],null,'monster')
			this.getUserOptions()
			//await this.addItemTo(this.userOptions)
			if(this.cmi+1 > 5) {
				this.cmi = 0
				this.mStrengthBooster++
			}
			this.monsterHealth = this.monsters[this.cmi].strength * this.mStrengthBooster
			this.attackCount = 0
			this.monsterStepCounter = 0
			this.battleHistory = {"beforeBattle":[],"inBattle":[],"allActions":[],"cheatcodeTracker": {"Death":[],"Critcal":[],"Major":[],"Minor":[]}}
		} else if( this.monsterHealth > 0 && this.monsterToFight === true ) {
			var that = this
			this.battleHistory.inBattle.push(this.userCurrentAction)
			// Return Enemy valyes
			//var weapon = this.currentMonster.weapon
			//var strength = this.weapons[weapon]['baseDamage'] //this.currentMonster.strength
			//var ouch = this.ouch()
			if(this.monsterHealth > 0) {
				//await that.addItemTo(name +" hits you with his "+ weapon +" for <span class='monster-hit-points'>"+ strength + "</span> points. <span class='ouchy'>"+ouch+"</span>",null,'monster')
			}
			if(that.userHealth < 1) {
				await that.addItemTo("You're dead meat! "+this.currentMonster.name+" ate your wife and kids. Have a nice day.",null,'death')
				this.userTurn = false
			} else {
				that.getUserOptions()
				await that.addItemTo(that.userOptions)
				this.userTurn = true
			}
		} else {
			this.battleHistory.beforeBattle.push(this.userCurrentAction)
			let stepsAway = this.currentMonster?.stepsAway ? this.currentMonster.stepsAway : 3
			let wait = this.currentMonster?.waitSteps ? this.currentMonster.waitSteps : 0
			if( this.monsterStepCounter > 0 &&  this.monsterStepCounter < (stepsAway + wait) ) {
			// Monster out of range message
			let mosterDistance = (stepsAway + wait) - this.monsterStepCounter
			let monsterRetort = await this.retort(this.currentMonster,this.battleHistory.beforeBattle,this.userCurrentAction)
			if(this.monsterStepCounter < wait) {
				await this.addItemTo("All is quiet, for now...",null,'general')
			} else {
				await this.monsterWalkActions(monsterRetort)	
			}
			
			console.log("----- // MONSTER MOVEMENT // ------")
			console.log("Distance:"+mosterDistance)
			console.log("Step:"+this.monsterStepCounter)
			this.getUserOptions()
			await this.addItemTo(this.userOptions)
			} else if( this.monsterStepCounter == (stepsAway + wait) ) {
				//let ouch = this.ouch()
				//if( this.monsterStepCounter == 4 ) {
				//await this.addItemTo(this.currentMonster.name+" the "+this.currentMonster.type+" is right next to you. He hits you with his "+ this.currentMonster.weapon +" for <span class='monster-hit-points'>"+this.weapons[this.currentMonster.weapon]['baseDamage'] + "</span> points. <span class='ouchy'>"+ouch+"</span>",null,'monster')
				await this.addItemTo("<span style='color: #ff22ff'>" + this.currentMonster.name+" the "+this.currentMonster.type+" is in front of you. </span>",null,'monster')
				this.monsterToFight = true
				// this.monsterVisible = true
				//}
				this.getUserOptions()
				let keyPressed = this.currentUserChoices[this.currentUserChoices.length - 1]
				let optionKey = this.getOptionKey(keyPressed)
				await this.addItemTo(this.userOptions)
				if(this.choices[optionKey]) {
					//this.handleChoice(this.choices[optionKey],true)	
				}
			} else {
				//await this.addItemTo(" Tets.",null,'monster')
				this.getUserOptions()
				this.monsterStepCounter = 0
				await this.addItemTo(this.userOptions)
			}
		}
	}
  },
}
</script>

<style scoped>

div, li {
  padding: 3px 0;
  font-family: Operator Mono, Menlo;
  line-height: 1.5;
}

.game-interface {
  background-color: #000;
  font-weight: bold;
  position: fixed;
  padding-top:10px;
  z-index: 100;
  line-height: 1.5;
  width: 100%;
  border-bottom: 1px solid #88ddff50;
  margin-left: 40px
}

.turn-counter-label {
  font-size: 20px;
}

.turn-counter {
  font-size: 30px;
}

.game-action {
  /* margin-top: 10px; */
  font-size: 20px;
}

.game-choices {
  margin-top: 10px;
}

.choice {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.game-outcome {
  /* margin-top: 10px; */
  font-size: 20px;
}

.health {
  color: #88ddff
}
.monster {
  color: #ff5555
}
.killcount {
  color: #98ec65;
}
.weapon {
  color: #ff7700
}
.pickup {
    color: #ff7700 !important;
}
.weapon-listing-container {
	position: fixed;
    right: 0;
    width: 30%;
    top: 0;
    height: 100%;
    background: #251b13;
    border: 1px solid #ff7700;
    padding: 20px !important;
    margin: 40px;
    z-index: 10000;
}
.weapon-listing-container li {
	color: #ff7700
}
.playground-container {
	padding-top: 45px;
	background: #000;
	position:relative;
	color: #ffffff;
	text-align: left;
	overflow: auto;
}
.playground-container .user-msg {
	background:#000;
	color: white;
	width: 100%;
	/* border-top: 1px solid #cccccc50; */
	}
.playground-container .death-msg {
	background:#000;
	color: #98ec65;
  font-weight: bold;
	width:max-content;
	}
.playground-container .monster-msg {
	background:#000;
	color: #ff5555;
	width:max-content;
	}
.playground-container .general-msg {
	background:#000;
	color: #88ddff;
	width:max-content;
	line-height: 1.5
}
.playground-container .info-msg {
	background:#000;
	color: white;
	width:max-content;
	}
span.user-hit-points {
  font-weight: bold !important;
}


span.retort {
    color: red;
    text-transform: uppercase;
}
</style>